import { Menu } from "@mantine/core";
import React from "react";
import useGlobalState, { globalFolder, globalProfile } from "../../globalState";
import { MenuAction, MenuSection } from "./menus";
import User from "../../model/User";

export default function LoaderMenu({
  menuConfig,
  targetElement,
  onActionClick,
  ctx,
  onOpened,
  onClosed,
}) {
  const [profile, setProfile] = useGlobalState<User>(globalProfile);

  const showMenuAction = (menuAction: MenuAction) => {
    if (!!menuAction.showIf) {
      return menuAction.showIf(ctx, profile?.email);
    }
    return true;
  };

  const showMenuSection = (menuSection: MenuSection) => {
    return menuSection.actions.some(showMenuAction);
  };

  const renderMenu = () => {
    return menuConfig.filter(showMenuSection).map((menuSection, index1) => {
      return (
        <div key={index1 + "menuSection"}>
          {index1 === 0 ? "" : <Menu.Divider></Menu.Divider>}

          <Menu.Label>{menuSection.name}</Menu.Label>

          {menuSection.actions
            .filter(showMenuAction)
            .map((menuAction, index2) => {
              return (
                <Menu.Item
                  {...menuAction.elementProps}
                  key={index1 + index2 + "menuAction"}
                  onClick={() => onActionClick(menuAction, ctx)}
                  leftSection={<menuAction.icon className="flex w-4 h-4" />}
                  disabled={
                    !!menuAction?.disabledIf
                      ? menuAction?.disabledIf(ctx, profile?.email)
                      : false
                  }
                >
                  {menuAction.name}
                  {menuAction?.description ? (
                    <div className="text-xs text-grey-600 mt-1">
                      {menuAction.description}
                    </div>
                  ) : (
                    ""
                  )}
                </Menu.Item>
              );
            })}
        </div>
      );
    });
  };

  return (
    <Menu
      shadow="md"
      width={200}
      offset={0}
      position="bottom-end"
      onOpen={onOpened}
      onClose={onClosed}
    >
      <Menu.Target>{targetElement}</Menu.Target>

      <Menu.Dropdown>{renderMenu()}</Menu.Dropdown>
    </Menu>
  );
}
