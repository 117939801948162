import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Group,
  Input,
  Switch,
  Text,
  CloseButton,
  useComputedColorScheme,
  useMantineTheme,
  TextInput,
  Select,
  SelectProps,
  FileButton,
  Chip,
} from "@mantine/core";
import { useMantineColorScheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  HiArrowDown,
  HiCheck,
  HiClock,
  HiCog,
  HiFolder,
  HiOutlineCloudDownload,
  HiOutlineCog,
  HiOutlineTemplate,
  HiSortAscending,
  HiSortDescending,
  HiTemplate,
} from "react-icons/hi";
import Gallery from "../gallery/Gallery";
import useApi from "../../services/useApi";

export default function GeneralGallery() {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const computedColorScheme = useComputedColorScheme("dark");

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const { searchFiles } = useApi();

  useEffect(() => {
    if (initialized) return;

    searchFiles(1).then((response) => {
      console.log(response);
      setFiles(response);
      setPage(0);
      setInitialized(true);
    });
  }, []);

  const loadMore = () => {
    if (isLoading) return;
    setIsLoading(true);
    searchFiles(page + 1).then((response) => {
      setFiles([...files, ...response]);
      // TODO: save data as global state to allow page changes
      setIsLoading(false);
      setPage(page + 1);
    });
  };

  const renderLoadMoreButton = () => {
    const hasNoFiles = files.length == 0;
    const isLastPage = files.length % 30 !== 0;

    if (!initialized || isLastPage || hasNoFiles) {
      return <></>;
    }

    return (
      <div className="flex justify-center p-4">
        <Button
          onClick={loadMore}
          variant="default"
          leftSection={<HiOutlineCloudDownload />}
          loading={isLoading}
        >
          Load more
        </Button>
      </div>
    );
  };

  const icons: Record<string, React.ReactNode> = {
    dateCreated: <HiClock />,
  };

  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      {icons[option.value]}
      {option.label}
      {checked && <HiCheck></HiCheck>}
    </Group>
  );

  return (
    <Container
      className="w-full mx-auto"
      p={isMobile ? "md" : "xl"}
      maw={"40rem"}
    >
      {/*<div className="flex flex-col">
        <Input
          variant="filled"
          size="md"
          radius="md"
          placeholder="Holidays in Tenerife"
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          mt="md"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue("")}
              style={{ display: searchValue ? undefined : "none" }}
            />
          }
        />
      </div>*/}

      <div className="grid grid-cols-2 mb-4">
        <div className="col-span-3 mb-4">
          <Select
            label="Source folder"
            className="col-span-1 col-start-3"
            data={[{ value: "allMyFolders", label: "All my folders" }]}
            renderOption={renderSelectOption}
            defaultValue="allMyFolders"
            leftSectionPointerEvents="none"
            leftSection={<HiFolder />}
            comboboxProps={{
              position: "bottom",
              middlewares: { flip: false, shift: false },
              offset: 0,
              shadow: "md",
              transitionProps: { transition: "pop", duration: 200 },
            }}
          />
        </div>

        <Chip.Group>
          <Group justify="start" className="flex flex-row gap-2">
            <Chip value="1" defaultChecked={true}>
              Last uploaded
            </Chip>
            <Chip value="2">Last created</Chip>
          </Group>
        </Chip.Group>

        <div className="flex flex-row col-span-1 col-start-3 gap-2">
          <Button variant="default" className="flex">
            <HiSortDescending className="flex w-4 h-4"></HiSortDescending>
          </Button>
          <Select
            className="col-span-1 col-start-3"
            data={[{ value: "dateCreated", label: "Creation date" }]}
            renderOption={renderSelectOption}
            defaultValue="dateCreated"
            leftSectionPointerEvents="none"
            leftSection={<HiSortDescending />}
            comboboxProps={{
              position: "bottom",
              middlewares: { flip: false, shift: false },
              offset: 0,
              shadow: "md",
              transitionProps: { transition: "pop", duration: 200 },
            }}
          />
        </div>
      </div>

      <div className="flex flex-col pb-2 overflow-auto w-full">
        <Gallery
          folders={[]}
          files={files}
          loadMoreFn={loadMore}
          onFolderClickFn={() => {}}
          onActionClick={() => {}}
          isSelectionEnabled={false}
          hiddenOptions={true}
        ></Gallery>
        {renderLoadMoreButton()}
      </div>
    </Container>
  );
}
