import { LayoutType } from "react-photo-album";

export interface Settings {
    photoAlbum_layout: LayoutType;
    photoAlbum_targetRowHeight: number;
    photoAlbum_spacing: number;
    slideshow_delay: number;
}

export const SETTINGS_DEFAULTS: Settings = {
    photoAlbum_layout: "rows",
    photoAlbum_targetRowHeight: 200,
    photoAlbum_spacing: 4,
    slideshow_delay: 5000,
};