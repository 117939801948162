import {
  Button,
  Card,
  Container,
  Divider,
  RingProgress,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { getAuth } from "firebase/auth";
import React from "react";
import { HiUser } from "react-icons/hi";
import { HiBolt } from "react-icons/hi2";
import useGlobalState, { globalProfile } from "../../globalState";
import { Plan } from "../../model/Plan";
import { formatBytes } from "../../utils/Utils";
import User from "../../model/User";

const plans = [
  {
    id: Plan.FREE,
    name: "Free",
    price: "0 $",
    features: [
      {
        name: "Storage",
        description: "1 GB",
      },
      {
        name: "Share limits",
        description: "5 users",
      },
    ],
  },
  {
    id: Plan.PREMIUM,
    name: "Pro",
    price: "9.99 $",
    features: [
      {
        name: "Storage",
        description: "100 GB",
      },
      {
        name: "Share limits",
        description: "Unlimited",
      },
    ],
  },
];

export default function Account() {
  const [profile, setProfile] = useGlobalState<User>(globalProfile);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const getStorageUsedPercentage = () => {
    return (profile?.storageStatus.used / profile?.storageStatus.total) * 100;
  };

  const getStorageUsedGraphColor = () => {
    const usedPercentage = getStorageUsedPercentage();
    if (usedPercentage > 80) {
      return "red";
    } else if (usedPercentage > 60) {
      return "yellow";
    } else {
      return "blue";
    }
  };

  const renderUpgradeButton = (plan: Plan) => {
    if (profile?.plan === plan) {
      return <></>;
    }

    return (
      <Button className="mt-6" leftSection={<HiBolt />}>
        Upgrade
      </Button>
    );
  };

  const renderPlansCard = () => {
    return (
      <div className="card">
        <Text fz="lg" mb="lg" fw={500}>
          Plan
        </Text>

        <div className="flex flex-col xs:flex-row justify-between items-start gap-4">
          {plans.map((plan) => (
            <Card
              className="w-full"
              bd={
                plan.id === profile?.plan || !profile?.plan
                  ? "2px solid var(--mantine-color-blue-4)"
                  : ""
              }
            >
              <div className="flex flex-col gap-4">
                <Text fz="lg" fw={500}>
                  {plan.name}
                </Text>
                <Text fz="lg" c="dimmed">
                  {plan.price} / month
                </Text>
              </div>

              <Divider className="my-4" />

              <div className="flex flex-col gap-4">
                {plan?.features.map((feature) => (
                  <div className="flex flex-row justify-between items-center">
                    <Text c="dimmed" size="sm">
                      {feature.name}
                    </Text>
                    <Text>{feature.description}</Text>
                  </div>
                ))}
              </div>

              {renderUpgradeButton(plan.id)}
            </Card>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Container
      className="w-full mx-auto flex flex-col gap-4"
      p={isMobile ? "md" : "xl"}
      maw={"40rem"}
    >
      <div className="flex flex-col gap-4 card">
        <Text mt={6} fz="lg" fw={500}>
          Account details
        </Text>
        <Divider mt={2} mb={4} />
        <div className="flex flex-row justify-start items-center gap-2 p-2 select-none flex-wrap">
          {getAuth().currentUser?.photoURL ? (
            <img
              className="h-7 w-7 min-h-7 min-w-7 m-auto rounded-full overflow-hidden"
              src={getAuth().currentUser?.photoURL}
              alt=""
            />
          ) : (
            <HiUser className="h-5 w-5"></HiUser>
          )}
          <Container hidden={!isMobile} className="flex flex-1 flex-col">
            <Text fz="lg" fw={500}>
              {getAuth().currentUser?.displayName}
            </Text>
            <Text mt={2} fz="md" c="dimmed">
              {getAuth().currentUser?.email}
            </Text>
          </Container>
        </div>
      </div>

      <div className="card">
        <Text fz="lg" mb="lg" fw={500}>
          Storage usage
        </Text>
        <Divider mt={2} mb={4} />
        <div className="flex flex-row gap-2 items-center justify-center xs:gap-8">
          <div className="flex flex-col items-center justify-center w-fit">
            <RingProgress
              className="w-min"
              size={120}
              thickness={12}
              roundCaps={false}
              sections={[
                {
                  value: getStorageUsedPercentage(),
                  color: getStorageUsedGraphColor(),
                },
              ]}
              label={
                <Text
                  c={getStorageUsedGraphColor()}
                  fw={700}
                  ta="center"
                  size="xl"
                >
                  {getStorageUsedPercentage().toFixed(0)}%
                </Text>
              }
            />
          </div>

          <div className="flex flex-col items-start justify-start sm:border-row gap-3">
            <div className="flex flex-col">
              <div className="w-28 text-grey-600">Total storage</div>
              <div className="text-lg">
                {formatBytes(profile?.storageStatus.total)}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="w-28 text-grey-600">Used storage</div>
              <div className="text-lg">
                {formatBytes(profile?.storageStatus.used)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*renderPlansCard()*/}
    </Container>
  );
}
