import {
  createTheme,
  MantineColorsTuple,
  MantineProvider,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import SignInPage from "./components/auth/SignInPage";
import Loader from "./components/loader/Loader";
import Settings from "./components/settings/Settings";

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
import "@mantine/dropzone/styles.css";
import Account from "./components/account/Account";
import Admin from "./components/admin/Admin";
import ErrorPage from "./components/error/ErrorPage";
import MapView from "./components/mapView/MapView";
import Resolver from "./components/resolver/Resolver";
import GeneralGallery from "./components/generalGallery/GeneralGallery";
// import '@mantine/code-highlight/styles.css';
// ...

import { store } from "./app/store";
import { Provider } from "react-redux";
import { createRef } from "react";
import BottomDrawer from "./components/bottomSheet/BottomDrawer";

const myColor: MantineColorsTuple = [
  "#e7f9ff",
  "#d5effa",
  "#abddf2",
  "#7ecbea",
  "#5bbce4",
  "#45b2e0",
  "#38addf",
  "#2898c7",
  "#1787b2",
  "#00759e",
];

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: { myColor },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const routes = [
  {
    path: "/",
    element: <Navigate to="/browse" replace />,
  },
  {
    path: "/",
    element: <App />,
    //errorElement: <ErrorPage />,
    children: [
      {
        path: "settings",
        element: <Settings />,
        nodeRef: createRef(),
      },
      {
        path: "account",
        element: (
          <Resolver waitFor="profile">
            <Account />
          </Resolver>
        ),
        nodeRef: createRef(),
      },
      {
        path: "gallery",
        element: (
          <Resolver waitFor="profile">
            <GeneralGallery />
          </Resolver>
        ),
        nodeRef: createRef(),
      },
      {
        path: "map",
        element: <MapView />,
        nodeRef: createRef(),
      },
      {
        path: "/admin",
        element: <Admin />,
        nodeRef: createRef(),
      },
      {
        path: "browse",
        element: (
          <Resolver waitFor="profile">
            <Loader />
          </Resolver>
        ),
        children: [
          {
            path: ":folder/*",
            //element: <Gallery />,
            // loader: folderLoader,
          },
        ],
        nodeRef: createRef(),
      },
    ],
  },
  {
    path: "/login",
    element: <SignInPage />,
    nodeRef: createRef(),
  },
  {
    path: "*",
    element: <ErrorPage />,
    nodeRef: createRef(),
  },
];

const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme} defaultColorScheme="dark">
        <Notifications />
        <ModalsProvider>
          <RouterProvider router={router} />
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
