import { getAuth } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export function useAuth() {
    const [authState, setAuthState] = useState({
        isSignedIn: false,
        pending: true,
        user: null,
    })

    const [auth, setAuth] = useState(getAuth());

    const navigate = useNavigate();

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            setAuthState({ user, pending: false, isSignedIn: !!user })
        })
        return () => unregisterAuthObserver()
    }, [])

    /**
     * Validate if the user is authenticated and redirect to login page if not
     * @returns {boolean} true if the user is authenticated, false otherwise
     */
    const validateAuth = (): boolean => {
        if (!auth.currentUser) {
            let redirectUrl = window.location.pathname;
            if (redirectUrl === "/login") {
                redirectUrl = "/browse";
            }
            navigate("/login?next=" + encodeURIComponent(redirectUrl));
            return false;
        }
        return true;
    }

    return { auth, validateAuth, ...authState }
}