import {
  Checkbox,
  Menu,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HiArrowLeft,
  HiDotsVertical,
  HiHome,
  HiQrcode,
  HiShare,
  HiUsers,
  HiViewGrid,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useGlobalState, { globalFolder } from "../../globalState";
import Folder from "../../model/Folder";
import LoaderMenu from "../menu/LoaderMenu";
import { BOTTOM_DRAWER_QUICK_ACTIONS_CONFIG, LOADER_MENU_CONFIG } from "../menu/menus";
import "./Breadcrumb.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  resetToInitialState,
  selectCurrentFolder,
} from "../../state/explorer/explorerSlice";
import BottomDrawer from "../bottomSheet/BottomDrawer";
import { HiMiniQrCode } from "react-icons/hi2";

interface BreadcrumbNode {
  folderId: string;
  folderName: string;
}

const useResizeEffect = (onResize, deps = []) => {
  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, onResize]);
};

export default function Breadcrumb({
  onActionClick,
  setIsLoaderOverlay,
  onNavigate,
  selectedFiles,
  setSelectedFiles,
}) {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const computedColorScheme = useComputedColorScheme("dark");

  const dispatch = useAppDispatch();
  const folder = useAppSelector(selectCurrentFolder);

  // Overflow
  const [overflowActive, setOverflowActive] = useState(false);
  const [container, setContainer] = useState(null);
  const breadcrumbRef = useRef(container);
  breadcrumbRef.current = container;

  const setOverflowActiveOnResize = useCallback(() => {
    if (!breadcrumbRef.current) return;
    const { clientWidth, scrollWidth } = breadcrumbRef.current;
    setOverflowActive(scrollWidth > clientWidth);
  }, [breadcrumbRef]);

  useResizeEffect(setOverflowActiveOnResize, [breadcrumbRef]);
  // -------

  useEffect(() => {
    if (!folder) {
      setItems([]);
      return;
    }
    if (!folder.path) {
      setItems([]);
      return;
    }
    const breadcrumbNodes = folder.path.split(";").map((node: string) => {
      const splitNode = node.split(":");
      return { folderId: splitNode[0], folderName: splitNode[1] };
    });
    setItems(breadcrumbNodes);
  }, [folder]);

  const onBreadcrumbItemClick = (item: BreadcrumbNode) => {
    if (item.folderId === (folder as Folder).id) {
      return;
    }
    onNavigate();
    navigate(`/browse/${item.folderId}`);
  };

  const navigateHome = () => {
    onNavigate();
    dispatch(resetToInitialState());
    navigate("/browse");
  };

  const onBackClick = () => {
    const index = items.length - 2;

    if (index === -1) {
      navigateHome();
    }
    if (index < 0) {
      return;
    }

    onBreadcrumbItemClick(items[index]);
  };

  const renderHomeBreadcrumbNode = () => (
    <div
      key={"0-home"}
      onClick={navigateHome}
      className="cursor-pointer hover:text-blue-400"
    >
      {<HiHome></HiHome>}
    </div>
  );

  const renderBreadcrumbNode = (item: BreadcrumbNode, index: number) => {
    return (
      <div
        onClick={() => onBreadcrumbItemClick(item)}
        className="cursor-pointer hover:text-blue-400"
      >
        {item.folderName}
      </div>
    );
  };

  const renderBreadcrumbDropdown = (target, props) => {
    return (
      <Menu
        shadow="md"
        width={props?.width ?? 400}
        offset={props?.offset ?? 10}
        position={props?.position ?? "bottom-start"}
        onOpen={() => setIsLoaderOverlay(true)}
        onClose={() => setIsLoaderOverlay(false)}
      >
        <Menu.Target>{target}</Menu.Target>

        <Menu.Dropdown>
          {items.map((item, index) => {
            return (
              <div key={index + item.folderName + "breadcrumbNode-divider"}>
                <Menu.Item
                  key={index + item.folderName + "breadcrumbNode-menuitem"}
                  className="cursor-pointer hover:text-blue-400"
                  onClick={() => onBreadcrumbItemClick(item)}
                >
                  {item?.folderName}
                </Menu.Item>
                {index >= items.length - 1 ? "" : <Menu.Divider />}
              </div>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    );
  };

  const renderOverflowedBreadcrumb = () => {
    const index = items.length - 1;
    const item = items[index];

    const target = (
      <div className="flex flex-row gap-2 cursor-pointer hover:text-blue-400">
        <div
          key={"breadcrumbNode-separator--overflow"}
          className="flex gap-2 hover:text-blue"
        >
          / ...
        </div>
        <div className="hover:text-blue flex flex-row gap-2">
          <div>/</div>
          <div>{item?.folderName}</div>
        </div>
      </div>
    );

    return renderBreadcrumbDropdown(target, {
      width: 500,
      offset: 10,
      position: "bottom",
    });
  };

  const renderSelectedFilesText = () => {
    if (selectedFiles === null) {
      return null;
    }

    return `(${selectedFiles.length} ${
      selectedFiles.length > 1 ? "files" : "file"
    } selected)`;
  };

  const renderContent = () => {
    if (selectedFiles === null || selectedFiles.length > 0) {
      return (
        <div className="relative flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden">
          <div
            className={`flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden ${
              overflowActive ? "invisible" : "visible"
            }`}
            ref={(element) => {
              if (!element) return;
              setContainer(element);
              const { clientWidth, scrollWidth } = element;
              setOverflowActive(scrollWidth > clientWidth);
            }}
          >
            <div className="flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden">
              <Checkbox
                className="text-blue-400 cursor-pointer"
                onClick={() => {
                  selectedFiles == null
                    ? setSelectedFiles([])
                    : setSelectedFiles(null);
                }}
              />
              {"Select all"}
            </div>

            {renderSelectedFilesText()}
          </div>
        </div>
      );
    }
    return (
      <div className="relative flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden">
        {/* DEFAULT BREADCRUMB */}
        <div
          className={`flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden ${
            overflowActive ? "invisible" : "visible"
          }`}
          ref={(element) => {
            if (!element) return;
            setContainer(element);
            const { clientWidth, scrollWidth } = element;
            setOverflowActive(scrollWidth > clientWidth);
          }}
        >
          {renderHomeBreadcrumbNode()}
          {items.map((item, index) => {
            return (
              <div
                key={index + item.folderNaame + "breadcrumbNode"}
                className="flex gap-2"
              >
                /{renderBreadcrumbNode(item, index)}
              </div>
            );
          })}
        </div>

        {/* OVERFLOWED BREADCRUMB */}
        <div
          className={`absolute flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden ${
            overflowActive ? "visible" : "invisible"
          }`}
        >
          {renderHomeBreadcrumbNode()}
          {renderOverflowedBreadcrumb()}
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div
        className="w-full h-12 min-h-12 flex flex-row justify-between items-center 
      dark:bg-dark-200 bg-[#e8e8e8] border-b-2 border-grey-300 dark:border-dark-300"
      >
        <div className="flex items-center flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
          {items.length > 0 ? (
            <div
              className="flex justify-center items-center p-4 mr-2 dark:hover:bg-dark-900 dark:active:bg-dark-999 hover:bg-grey-200 active:bg-grey-300 cursor-pointer"
              onClick={() => onBackClick()}
            >
              <HiArrowLeft className="flex w-4 h-4"></HiArrowLeft>
            </div>
          ) : (
            ""
          )}
          {renderBreadcrumbDropdown(
            <div className="w-full h-full cursor-pointer hover:text-blue-400">
              <div>{folder?.name}</div>
            </div>,
            {
              width: "100%",
              offset: 10,
              position: "bottom-start",
            }
          )}
        </div>
        <div className="flex">
          <BottomDrawer
            ctx={{ folder }}
            menuConfig={LOADER_MENU_CONFIG}
            actionsConfig={BOTTOM_DRAWER_QUICK_ACTIONS_CONFIG}
            onActionClick={onActionClick}
            targetElement={
              <div
                className="flex justify-center items-center dark:hover:bg-dark-900 hover:bg-grey-200 dark:active:bg-dark-999 active:bg-grey-300 cursor-pointer
              rounded-xl p-2 mr-2"
              >
                <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
              </div>
            }
          ></BottomDrawer>
        </div>
      </div>
    );
  }

  return (
    <div
      className={` h-10 min-h-10 flex flex-row justify-between items-center m-2 mx-4 ${
        !isMobile ? "fixed top-0 z-50 w-[80%] sm:w-[90%] lg:w-[95%]" : ""
      }`}
    >
      <div
        className="w-full h-10 min-h-10 flex flex-row justify-between items-center pl-4 dark:bg-dark-100 bg-grey-200
    my-2 mx-4 rounded-lg box-border"
      >
        <div className="flex flex-row items-center gap-2 flex-nowrap text-nowrap max-w-full overflow-hidden">
          {renderContent()}
        </div>

        <div className="flex">
          <LoaderMenu
            ctx={{ folder }}
            menuConfig={LOADER_MENU_CONFIG}
            onActionClick={onActionClick}
            targetElement={
              <div
                className="flex justify-center items-center dark:hover:bg-dark-900 hover:bg-grey-200 dark:active:bg-dark-999 active:bg-grey-300 cursor-pointer
            rounded-xl p-2 mr-2"
              >
                <HiDotsVertical className="flex w-4 h-4"></HiDotsVertical>
              </div>
            }
            onOpened={() => {
              setIsLoaderOverlay(true);
            }}
            onClosed={() => {
              setIsLoaderOverlay(false);
            }}
          ></LoaderMenu>
        </div>
      </div>
    </div>
  );
}
