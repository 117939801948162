import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import Folder from '../../model/Folder'
import File from '../../model/File';

// Define a type for the slice state
interface ExplorerState {
    currentFolderId: string;
    currentFolder: Folder;
    folders: Folder[];
    files: File[];
    page: number;
}

// Define the initial state using that type
// Initial value in explorer is used in /browse
const initialState: ExplorerState = {
    currentFolderId: null,
    currentFolder: null,
    folders: [],
    files: [],
    page: 0,
}

export const explorerSlice = createSlice({
    name: 'explorer',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        resetToInitialState: state => {
            state.currentFolderId = null
            state.currentFolder = null
            state.folders = []
            state.files = []
            state.page = 0
        },

        setNewFolder: (state, action: PayloadAction<Folder>) => {
            state.currentFolder = action.payload
            state.currentFolderId = action.payload.id
            state.page = 0
            state.files = action.payload.files
            state.folders = action.payload.folders
        },

        addFiles: (state, action: PayloadAction<File[]>) => {
            state.files = [...state.files, ...action.payload]
        },

        setFiles: (state, action: PayloadAction<File[]>) => {
            state.files = action.payload
        },

        addFolders: (state, action: PayloadAction<Folder[]>) => {
            state.folders = [...state.folders, ...action.payload]
        },

        setFolders: (state, action: PayloadAction<Folder[]>) => {
            state.folders = action.payload
        },

        incrementPage: (state) => {
            state.page += 1
        },
    }
})

export const {
    resetToInitialState,
    setNewFolder,
    addFiles,
    setFiles,
    addFolders,
    setFolders,
    incrementPage,
} = explorerSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentFolder = (state: RootState) => state.explorer.currentFolder

export default explorerSlice.reducer