import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useRef, createRef } from "react";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import { useAuth } from "./services/useAuth";
import useGlobalState, { globalProfile, globalSettings } from "./globalState";
import useApi from "./services/useApi";
import { SETTINGS_DEFAULTS } from "./settings";
import Resolver from "./components/resolver/Resolver";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { routes } from ".";

function App() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const navigate = useNavigate();
  const { validateAuth, user, pending, isSignedIn } = useAuth();
  const [profile, setProfile] = useGlobalState(globalProfile);
  const [settings, setSettings] = useGlobalState(globalSettings);
  const { fetchProfile, loading } = useApi();
  const location = useLocation();

  const currentOutlet = useOutlet();
  const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {};

  useEffect(() => {
    if (pending) return;
    if (!user) validateAuth();
  }, [pending]);

  useEffect(() => {
    if (location == null) return;
    if (!pending && isSignedIn) fetchAndSaveProfile();
  }, [location, pending, isSignedIn]);

  const fetchAndSaveProfile = () => {
    if (!!profile) {
      return;
    }

    // Set globalProfile
    fetchProfile().then((response) => {
      if (response === null) {
        navigate("/login");
        return;
      }
      setProfile(response);
    });

    // Set globalSettings
    const settings = localStorage.getItem("settings");
    if (settings != null && settings !== "" && settings !== undefined) {
      setSettings({
        ...SETTINGS_DEFAULTS,
        ...JSON.parse(settings),
      });
    } else {
      setSettings(SETTINGS_DEFAULTS);
    }
  };

  return (
    <div
      className={"flex h-full w-full " + (isMobile ? "flex-col" : "flex-row")}
    >
      <div>
        <Navbar></Navbar>
      </div>
      <div className="flex flex-col h-full w-full overflow-auto">
        <Resolver waitFor="settings">
          <div className="relative overflow-auto w-full h-full">
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                nodeRef={nodeRef}
                timeout={300}
                classNames="page"
                unmountOnExit
              >
                {(state) => (
                  <div ref={nodeRef as any} className="page">
                    {currentOutlet}
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          </div>
        </Resolver>
      </div>
    </div>
  );
}

export default App;
