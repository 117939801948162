export const exampleGalleryFiles = [
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "1",
        width: 640,
        height: 427,
        id: "1",
        name: "example-1.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-1.jpg",
        thumbnailUrl: "example-1.jpg",
        url: "example-1.jpg",
        uuid: "1",
    },
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "2",
        width: 427,
        height: 640,
        id: "2",
        name: "example-5.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-5.jpg",
        thumbnailUrl: "example-5.jpg",
        url: "example-5.jpg",
        uuid: "2",
    },
    /*{
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "3",
        width: 427,
        height: 640,
        id: "3",
        name: "example-5.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-5.jpg",
        thumbnailUrl: "example-5.jpg",
        url: "example-5.jpg",
        uuid: "3",
    },*/
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "4",
        width: 640,
        height: 427,
        id: "4",
        name: "example-3.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-3.jpg",
        thumbnailUrl: "example-3.jpg",
        url: "example-3.jpg",
        uuid: "4",
    },
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "5",
        width: 640,
        height: 427,
        id: "5",
        name: "example-4.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-4.jpg",
        thumbnailUrl: "example-4.jpg",
        url: "example-4.jpg",
        uuid: "5",
    },
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "6",
        width: 512,
        height: 640,
        id: "6",
        name: "example-6.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-6.jpg",
        thumbnailUrl: "example-6.jpg",
        url: "example-6.jpg",
        uuid: "6",
    },
    {
        dateCreated: "2024-09-14T22:51:36.289Z",
        folderId: "7",
        width: 640,
        height: 427,
        id: "7",
        name: "example-7.jpg",
        owner: "example@gmail.com",
        sizeInBytes: 2901858,
        src: "example-7.jpg",
        thumbnailUrl: "example-7.jpg",
        url: "example-7.jpg",
        uuid: "7",
    },
];