import { useState, useEffect } from "react";

export const globalProfile = createGlobalState(null);
export const globalToken = createGlobalState(null);
export const globalTokenData = createGlobalState(null);
export const globalFolder = createGlobalState(null);
export const globalSettings = createGlobalState(null);

function createGlobalState(initState = null) {
    const prototype = {
        data: { state: initState, reRenderFns: [] },

        get() {
            return this.data.state;
        },

        set(newState) {
            console.log('globalState SET', newState);
            if (newState === this.data.state) return;
            this.data.state = newState;
            this.data.reRenderFns.forEach((reRender) => reRender());
            return this;
        },

        joinReRender(reRender) {
            if (this.data.reRenderFns.includes(reRender)) return;
            this.data.reRenderFns.push(reRender);
        },

        cancelReRender(reRender) {
            this.data.reRenderFns = this.data.reRenderFns.filter(
                (reRenderFn) => reRenderFn !== reRender
            );
        },
    };

    return Object.freeze(Object.create(prototype));
}

export default function useGlobalState<S>(globalState): [S, any] {
    const [, set] = useState(globalState.get());
    const state = globalState.get();
    const reRender = () => set({});

    useEffect(() => {
        globalState.joinReRender(reRender);
        return () => {
            globalState.cancelReRender(reRender);
        };
    });

    function setState(newState) {
        globalState.set(newState);
    }

    return [state, setState];
}
